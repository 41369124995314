import request from "@/api/service";

const api = {
   page: '/pms/hardware/getPage',
   hardware: '/pms/hardware',
}

export function getPage(params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function saveHardware(params, method) {
   return request({
      url: api.hardware,
      method,
      data: params
   })
}

export function delHardware(query) {
   return request({
      url: api.hardware + query,
      method: 'DELETE'
   })
}

export function getHardwareDetail(query) {
   return request({
      url: api.hardware + query,
      method: 'GET'
   })
}
