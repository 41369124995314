<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="content-box">
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-tabs v-model="params.hardwareType" @tab-click="getHardwareRecords">
            <el-tab-pane v-for="(key, val) in { '': '全部', DOOR_LOCK: '门锁', ID_CARD_READER: '身份证' }"
                         :label="key" :name="val" :key="key"></el-tab-pane>
          </el-tabs>
          <div class="right-btn">
            <el-button @click="visible = true; action = 'add'" type="primary">添加</el-button>
          </div>
        </el-row>
        <el-table ref="mainTable" v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="110"></el-table-column>
          <el-table-column prop="productModel" label="产品型号" min-width="60"></el-table-column>
          <el-table-column label="硬件类别">
            <template slot-scope="scope">{{ scope.row.hardwareType | filterType(that) }}</template>
          </el-table-column>
          <el-table-column prop="configItem" label="配置项"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column label="操作" fixed="right" min-width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="handleLook(scope.row.id)">查看</el-button>
              <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" @click="handleDel(scope.row.id)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="params.limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </el-row>
    <el-dialog title="添加硬件对接记录" :visible.sync="visible" width="550px"
               :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="form" :model="formData">
        <el-form-item label="硬件类别">
          <el-select class="width-280" v-model="formData.hardwareType">
            <el-option v-for="(val, key) in dictData['hardware-type']" :key="key" :label="val" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌">
          <el-input class="width-280" v-model="formData.brandName" placeholder="品牌"></el-input>
        </el-form-item>
        <el-form-item label="品牌编码">
          <el-input class="width-280" v-model="formData.brandCode" placeholder="品牌编码"></el-input>
        </el-form-item>
        <el-form-item label="产品型号">
          <el-input class="width-280" v-model="formData.productModel" placeholder="产品型号"></el-input>
        </el-form-item>
        <el-form-item label="配置项">
          <el-input class="width-280" v-model="formData.configItem" placeholder="配置项"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input class="width-280" v-model="formData.remark" placeholder="备注" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="硬件对接记录详情" :visible.sync="visibleDetail" width="550px">
      <el-descriptions :column="2">
        <el-descriptions-item label="硬件类别">{{ detailData.hardwareType | filterType(that) }}</el-descriptions-item>
        <el-descriptions-item label="品牌">{{ detailData.brandName }}</el-descriptions-item>
        <el-descriptions-item label="添加产品型号">{{ detailData.productModel }}</el-descriptions-item>
        <el-descriptions-item label="添加配置项">{{ detailData.configItem }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ detailData.remark }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="visibleDetail = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, saveHardware, delHardware,
  getHardwareDetail } from "@/api/system/record";
import getDict from "@/utils/dataDict";
import { mapState } from 'vuex';
export default {
  name: 'hardwareDockingRecord',
  data () {
    return {
      crumbs: new Map([
          ['系统管理'], ['硬件对接记录']
      ]),
      that: this,
      total: 0,
      loading: false,
      params: {
        limit: 0,
        page: 1,
        hardwareType: ''
      },
      tableData: [],
      visible: false,
      action: 'add',
      formData: {
        id: '',
        hardwareType: 'DOOR_LOCK',
        brandName: '',
        brandCode: '',
        productModel: '',
        configItem: '',
        remark: ''
      },
      visibleDetail: false,
      detailData: {}
    }
  },
  computed: {
    ...mapState(['dictData'])
  },
  mounted() {
    this.params.limit = sessionStorage.getItem('pageSize') * 1;
    getDict('hardware-type')
    this.getHardwareRecords()
  },
  methods: {
    // 获取硬件对接记录列表
    getHardwareRecords() {
      const params = { ...this.params };
      params.hardwareType === '0' && (params.hardwareType = '')
      getPage(params).then(({ success, records, total }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.params.limit = num
      this.getHardwareRecords()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.params.page = num
      this.getHardwareRecords()
    },
    // 编辑记录
    handleEdit(row) {
      for (const k in this.formData) {
        k in row && (this.formData[k] = row[k])
      }
      this.visible = true
      this.action = 'edit'
    },
    // 保存记录
    handleSave() {
      const params = { ...this.formData };
      let method
      if (this.action === 'add') { method = 'POST'; delete params.id }
      else method = 'PUT'
      saveHardware(params, method).then(({ success }) => {
        if (success) {
          this.handleClose()
          this.getHardwareRecords()
          this.$message({ message: '保存成功！', type: 'success' })
        }
      })
    },
    // 关闭会话框
    handleClose() {
      this.visible = false
      for (const k in this.formData) {
        this.formData[k] = ''
      }
      this.formData.hardwareType = 'DOOR_LOCK'
    },
    // 删除记录
    handleDel(id) {
      this.$confirm('确定删除该记录？', '删除', {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: 'warning'
      }).then(() => {
        delHardware(`/${ id }`).then(({ success }) => {
          if (success) {
            this.getHardwareRecords()
            this.$message({ message: '删除成功！', type: 'success' })
          }
        })
      })
    },
    // 查看记录
    handleLook(id) {
      getHardwareDetail(`/${ id }`).then(({ success, data }) => {
        if (success) this.visibleDetail = true; this.detailData = data
      })
    }
  },
  filters: {
    filterType(val, that) {
      if (val && that.dictData['hardware-type']) return that.dictData['hardware-type'][val] || val
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .el-form {
    padding-left: 30px;
    ::v-deep .el-form-item__label { width: 70px }
  }
}
</style>
